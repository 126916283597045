<template>
  <v-container class="mt-10">
    <div v-if="loading" class="d-flex align-center justify-center">
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-row v-if="!loading && message" class="align-center mb-8">
      <v-col class="text-center">
        <v-card outlined max-width="960" elevation="12" class="mx-auto">
          <v-card-text>
            <h3 v-html="message"></h3>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <template v-if="!loading">
      <h1 v-if="organization" class="text-center mb-5">
        {{ organization.name }}
      </h1>
      <amplify-authenticator>
        <amplify-sign-in
          header-text="Advisor Access"
          slot="sign-in"
          submit-button-text="SIGN IN"
          username-alias="email"
          :hide-sign-up="false"
        ></amplify-sign-in>

        <amplify-sign-up
          header-text="Create Advisor Account"
          slot="sign-up"
          username-alias="email"
          :formFields.prop="formFields"
          :handleSignUp.prop="onHandleSignUp"
        ></amplify-sign-up>
        <!-- <amplify-confirm-sign-in
          header-text="My Custom Confirm Sign In Text"
          slot="confirm-sign-in"
        ></amplify-confirm-sign-in> -->
      </amplify-authenticator>
    </template>
  </v-container>
</template>
<script>
import { Auth, API } from "aws-amplify";
import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import { getOrganization } from "@/graphql/queries";
// import getUserCredentialsMixin from "@/mixins/getUserCredentialsMixin";

export default {
  // Check url for org id
  // Look up org id & org advisor signup code?
  // Check if org.active
  // Brand login with org data

  // On signup...
  // Check user provided signup code matches org code
  // Set user custom attribute organization with org id
  // Create user
  // Add user to advisor group
  // Create advisor?
  props: ["organizationId"],
  created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
    });
  },
  mounted() {
    // Get ID from route props
    // If prop organizationId, check it is a valid UUID
    if (this.organizationId) {
      this.loading = true;

      const regex = new RegExp(
        /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/
      );

      const regexValid = regex.test(this.organizationId);
      console.log("regexValid", regexValid);
      if (regexValid) {
        this.fetchOrganization(this.organizationId);
      } else {
        // this.message =
        //   "Please contact your organization for the proper access information.";
        this.loading = false;
      }
    }
    console.log("ID FROM PROPS", this.organizationId);
  },
  data() {
    return {
      loading: false,
      user: undefined,
      authState: undefined,
      unsubscribeAuth: undefined,
      message: null,
      organization: null,
      showEmailConfirm: false,
      formFields: [
        {
          type: "email",
          label: "Email Address",
          placeholder: "Enter your email address",
          inputProps: { required: true, autocomplete: "username" },
        },
        {
          type: "password",
          label: "Create Password",
          placeholder: "Enter a password",
          inputProps: { required: true, autocomplete: "new-password" },
        },
        {
          type: "accessCode",
          label: "Organization Code",
          placeholder: "Enter organization code",
          inputProps: { required: true },
        },
      ],
    };
  },
  beforeDestroy() {
    if (this.unsubscribeAuth) {
      this.unsubscribeAuth();
    }
  },
  methods: {
    async fetchOrganization(id) {
      try {
        const response = await API.graphql({
          query: getOrganization,
          variables: { id: id },
          authMode: "AWS_IAM",
        });

        const org = response.data.getOrganization;
        console.log("ORGANIZATION:", org);
        if (org) {
          this.organization = org;
        }
      } catch (error) {
        console.log("Error fetching organization", error);
      } finally {
        this.loading = false;
      }
    },
    async onHandleSignUp(formData) {
      this.loading = true;
      try {
        if (
          !this.organization ||
          !this.organization.active ||
          !formData.attributes.accessCode ||
          formData.attributes.accessCode !== this.organization.accessCode
        ) {
          console.log("NO CODE");
          throw Error(`<p><strong>Incorrect or Missing Access Code or Organization.</strong></p>
          <p>Please enter a valid access code or contact your organization if you need assistance.</p>`);
        }

        console.log("eeee", formData);

        const authData = await Auth.signUp({
          username: formData.username,
          password: formData.password,
          attributes: {
            email: formData.email,
            "custom:organization": this.organization.id,
          },
          autoSignIn: {
            // optional - enables auto sign in after user is confirmed
            enabled: true,
          },
          validationData: [], // optional
        });
        console.log("THE AUTH", Auth);
        console.log("NEW USER", authData);
        if (authData) {
          this.showEmailConfirm = true;
          this.message = `<p>Please check your email <strong>${authData.user.username}</strong> to confirm your email before you can gain access.</p>`;
        }
      } catch (error) {
        console.log("ERROR::::", error);
        this.message = `
          <p>${error}</p>`;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
